body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.translation-card {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

@media (pointer: coarse) { /* touch devices */
  .card-switch-hint-touch {
    display: block;
  }
  .card-switch-hint-mouse {
    display: none;
  }
  .translation-card {
    cursor: default;
  }
}

@media (pointer: fine) { /* mouse devices */
  .card-switch-hint-touch {
    display: none;
  }
  .card-switch-hint-mouse {
    display: block;
  }
  .translation-card {
    cursor: pointer;
  }
}

.visible-200 {
  opacity: 1;
  transition: opacity .2s;
}

.hidden-200 {
  opacity: 0;
  transition: opacity .2s;
}

@media (min-width: 1050px) {
  .navbar-menu-custom {
    max-width: 960px;
    margin: 0 auto;
  }
}

.navbar-item-custom {
  padding-right: 0;
  padding-left: 0;
}


@media (max-width: 1049px) {
  .navbar-item-custom {
    padding-right: 1.5em;
    padding-left: 1.5em;
  }
}

.has-background-selected {
  background-color: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-80-l), 1);
  border: 2px solid hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-80-l), 1);
}

.has-background-unselected {
  background-color: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-95-l), 1);
  border: 2px solid hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-80-l), 1);
}

/* Main theme colors */
.section {
  padding: 3rem 1.5rem;
}

.card.card-landing {
  border: 2px solid hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-l), 1);
  height: 100%;
}

.card.card-landing .title {
  color: hsla(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-l), 1);
  margin-bottom: 0.8rem;
}

.footer {
  background-color: #fbfcd5;
  padding: 2rem 1.5rem;
}

a.button.is-large {
  margin-top: 1.5rem;
}
